<template>
  <transition name="fade" mode="out-in" appear>
    <el-card
      v-if="
        (company && Object.keys(company.months).length > 0) ||
        this.$auth.check(['super', 'admin', 'employee'])
      "
    >
      <el-row type="flex" align="middle" style="margin-bottom: 1rem">
        <el-col style="flex: 0; margin-left: 2rem">
          <a
            @click="changeYear('left')"
            class="year-arrow has-cursor-pointer"
            :class="{
              active:
                $auth.check(['super', 'admin', 'employee']) ||
                (company && company.years.indexOf(year - 1) !== -1),
            }"
          >
            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
          </a>
        </el-col>
        <el-col style="flex: 1; text-align: center">
          <div class="year-number">{{ year }}</div>
        </el-col>
        <el-col style="flex: 0; margin-right: 2rem">
          <a
            @click="changeYear('right')"
            class="year-arrow has-cursor-pointer"
            :class="{
              active:
                $auth.check(['super', 'admin', 'employee']) ||
                (company && company.years.indexOf(year + 1) !== -1),
            }"
          >
            <font-awesome-icon icon="chevron-right" />
          </a>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        align="middle"
        justify="center"
        style="margin-bottom: 1rem; text-align: center"
        v-if="company.months.length === 0"
      >
        {{ company.name }} does not have any campaigns. Please enter a month and
        create one to continue.
      </el-row>
      <el-row :gutter="20" class="months-container" v-if="year">
        <el-col
          :xs="24"
          :sm="12"
          style="min-width: 49%"
          class="month-container"
        >
          <div
            class="month"
            :class="{
              'empty-month':
                company.campaigns && !company.campaigns['evergreen'],
            }"
          >
            <div
              class="month-name"
              @click="openMonth('evergreen')"
              :style="{
                cursor: !company.campaigns['evergreen']
                  ? $auth.check(['super', 'admin', 'employee'])
                    ? 'pointer'
                    : 'default'
                  : 'pointer',
              }"
            >
              Evergreen
              <font-awesome-icon
                icon="angle-right"
                v-if="
                  !company.campaigns['evergreen']
                    ? $auth.check(['super', 'admin', 'employee'])
                      ? 'pointer'
                      : 'default'
                    : 'pointer'
                "
                style="float: right; margin-right: 0.5rem"
              ></font-awesome-icon>
            </div>
            <template v-if="company.campaigns['evergreen']">
              <div
                v-for="campaign in company.campaigns['evergreen']"
                :key="campaign.slug"
                @click="openCampaign('evergreen', campaign)"
              >
                <el-row class="month-campaign">
                  <div class="campaign-name">{{ campaign.name }}</div>
                  <!--<div class="campaign-stats">-->
                  <!--<font-awesome-icon icon="check"></font-awesome-icon>-->
                  <!--{{ (campaign.assets - campaign.pending_assets ) }}/{{ campaign.assets }}-->
                  <!--</div>-->
                </el-row>
              </div>
            </template>
            <template v-else>
              <p>No campaigns available.</p>
            </template>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="months-container" v-if="year">
        <el-col
          :xs="24"
          :sm="12"
          :md="6"
          class="month-container"
          v-for="month in months"
          :key="month"
        >
          <div
            class="month"
            :class="{
              'empty-month': company.campaigns && !company.campaigns[month],
            }"
          >
            <div
              class="month-name"
              @click="openMonth(month)"
              :style="{
                cursor: !company.campaigns[month]
                  ? $auth.check(['super', 'admin', 'employee'])
                    ? 'pointer'
                    : 'default'
                  : 'pointer',
              }"
            >
              {{ getMonthName(month) }}
              <font-awesome-icon
                icon="angle-right"
                v-if="
                  !company.campaigns[month]
                    ? $auth.check(['super', 'admin', 'employee'])
                      ? 'pointer'
                      : 'default'
                    : 'pointer'
                "
                style="float: right; margin-right: 0.5rem"
              ></font-awesome-icon>
            </div>
            <template v-if="company.campaigns[month]">
              <div
                v-for="campaign in company.campaigns[month]"
                :key="campaign.slug"
                @click="openCampaign(month, campaign)"
              >
                <el-row class="month-campaign">
                  <div class="campaign-name">{{ campaign.name }}</div>
                  <!--<div class="campaign-stats">-->
                  <!--<font-awesome-icon icon="check"></font-awesome-icon>-->
                  <!--{{ (campaign.assets - campaign.pending_assets ) }}/{{ campaign.assets }}-->
                  <!--</div>-->
                </el-row>
              </div>
            </template>
            <template v-else>
              <p>No campaigns available.</p>
            </template>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card v-else>
      <el-row
        type="flex"
        align="middle"
        justify="center"
        style="margin-bottom: 1rem"
      >
        {{ company.name }} does not have any campaigns.
      </el-row>
    </el-card>
  </transition>
</template>

<style lang="scss">
@media only screen and (min-width: 768px) {
  .month-container {
    width: 49%;
  }
}

@media only screen and (min-width: 992px) {
  .month-container {
    width: 24%;
  }
}
</style>

<script>
import DateTime from "@/mixins/DateTime"

import { mapGetters } from "vuex"

export default {
  name: "Company",

  mixins: [DateTime],

  data() {
    return {
      month_names: {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
      },
      year: "",
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      companies: "companies/list",
    }),
    company() {
      let index = this.$_.findIndex(this.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.companies[index]
    },
    months() {
      return [
        "01-" + this.year,
        "02-" + this.year,
        "03-" + this.year,
        "04-" + this.year,
        "05-" + this.year,
        "06-" + this.year,
        "07-" + this.year,
        "08-" + this.year,
        "09-" + this.year,
        "10-" + this.year,
        "11-" + this.year,
        "12-" + this.year,
      ]
    },
  },
  watch: {
    $route() {
      this.routeChange()
    },
  },

  methods: {
    routeChange() {
      if (!this.company) {
        this.$router.replace("/404")
      } else {
        let now = this.dateTimeNow()
        if (
          this.company.years.length === 0 ||
          this.company.years.indexOf(now) !== -1
        ) {
          this.year = now
        } else {
          this.year = this.company.years[0]
        }

        document.title = this.$root.app.name + " - " + this.company.name
      }
    },
    getMonthName(month) {
      return this.month_names[month.substr(0, 2)]
    },
    openMonth(month) {
      if (
        this.$auth.check(["super", "admin", "employee"]) ||
        this.company.campaigns[month]
      ) {
        this.$router.push({
          name: "month",
          params: {
            company: this.company.slug,
            month: month,
          },
        })
      }
    },
    openCampaign(month, campaign) {
      this.$router.push({
        name: "month",
        params: {
          company: this.company.slug,
          month: month,
          campaign: campaign.slug,
        },
      })
    },
    changeYear(direction) {
      let next = direction === "left" ? this.year - 1 : this.year + 1
      if (
        this.$auth.check(["super", "admin", "employee"]) ||
        this.company.years.indexOf(next) !== -1
      ) {
        this.year = next
      }
    },
  },

  created() {
    this.routeChange()
  },
}
</script>
