var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(
      (_vm.company && Object.keys(_vm.company.months).length > 0) ||
      this.$auth.check(['super', 'admin', 'employee'])
    )?_c('el-card',[_c('el-row',{staticStyle:{"margin-bottom":"1rem"},attrs:{"type":"flex","align":"middle"}},[_c('el-col',{staticStyle:{"flex":"0","margin-left":"2rem"}},[_c('a',{staticClass:"year-arrow has-cursor-pointer",class:{
            active:
              _vm.$auth.check(['super', 'admin', 'employee']) ||
              (_vm.company && _vm.company.years.indexOf(_vm.year - 1) !== -1),
          },on:{"click":function($event){return _vm.changeYear('left')}}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1)]),_c('el-col',{staticStyle:{"flex":"1","text-align":"center"}},[_c('div',{staticClass:"year-number"},[_vm._v(_vm._s(_vm.year))])]),_c('el-col',{staticStyle:{"flex":"0","margin-right":"2rem"}},[_c('a',{staticClass:"year-arrow has-cursor-pointer",class:{
            active:
              _vm.$auth.check(['super', 'admin', 'employee']) ||
              (_vm.company && _vm.company.years.indexOf(_vm.year + 1) !== -1),
          },on:{"click":function($event){return _vm.changeYear('right')}}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)])],1),(_vm.company.months.length === 0)?_c('el-row',{staticStyle:{"margin-bottom":"1rem","text-align":"center"},attrs:{"type":"flex","align":"middle","justify":"center"}},[_vm._v(" "+_vm._s(_vm.company.name)+" does not have any campaigns. Please enter a month and create one to continue. ")]):_vm._e(),(_vm.year)?_c('el-row',{staticClass:"months-container",attrs:{"gutter":20}},[_c('el-col',{staticClass:"month-container",staticStyle:{"min-width":"49%"},attrs:{"xs":24,"sm":12}},[_c('div',{staticClass:"month",class:{
            'empty-month':
              _vm.company.campaigns && !_vm.company.campaigns['evergreen'],
          }},[_c('div',{staticClass:"month-name",style:({
              cursor: !_vm.company.campaigns['evergreen']
                ? _vm.$auth.check(['super', 'admin', 'employee'])
                  ? 'pointer'
                  : 'default'
                : 'pointer',
            }),on:{"click":function($event){return _vm.openMonth('evergreen')}}},[_vm._v(" Evergreen "),(
                !_vm.company.campaigns['evergreen']
                  ? _vm.$auth.check(['super', 'admin', 'employee'])
                    ? 'pointer'
                    : 'default'
                  : 'pointer'
              )?_c('font-awesome-icon',{staticStyle:{"float":"right","margin-right":"0.5rem"},attrs:{"icon":"angle-right"}}):_vm._e()],1),(_vm.company.campaigns['evergreen'])?_vm._l((_vm.company.campaigns['evergreen']),function(campaign){return _c('div',{key:campaign.slug,on:{"click":function($event){return _vm.openCampaign('evergreen', campaign)}}},[_c('el-row',{staticClass:"month-campaign"},[_c('div',{staticClass:"campaign-name"},[_vm._v(_vm._s(campaign.name))])])],1)}):[_c('p',[_vm._v("No campaigns available.")])]],2)])],1):_vm._e(),(_vm.year)?_c('el-row',{staticClass:"months-container",attrs:{"gutter":20}},_vm._l((_vm.months),function(month){return _c('el-col',{key:month,staticClass:"month-container",attrs:{"xs":24,"sm":12,"md":6}},[_c('div',{staticClass:"month",class:{
            'empty-month': _vm.company.campaigns && !_vm.company.campaigns[month],
          }},[_c('div',{staticClass:"month-name",style:({
              cursor: !_vm.company.campaigns[month]
                ? _vm.$auth.check(['super', 'admin', 'employee'])
                  ? 'pointer'
                  : 'default'
                : 'pointer',
            }),on:{"click":function($event){return _vm.openMonth(month)}}},[_vm._v(" "+_vm._s(_vm.getMonthName(month))+" "),(
                !_vm.company.campaigns[month]
                  ? _vm.$auth.check(['super', 'admin', 'employee'])
                    ? 'pointer'
                    : 'default'
                  : 'pointer'
              )?_c('font-awesome-icon',{staticStyle:{"float":"right","margin-right":"0.5rem"},attrs:{"icon":"angle-right"}}):_vm._e()],1),(_vm.company.campaigns[month])?_vm._l((_vm.company.campaigns[month]),function(campaign){return _c('div',{key:campaign.slug,on:{"click":function($event){return _vm.openCampaign(month, campaign)}}},[_c('el-row',{staticClass:"month-campaign"},[_c('div',{staticClass:"campaign-name"},[_vm._v(_vm._s(campaign.name))])])],1)}):[_c('p',[_vm._v("No campaigns available.")])]],2)])}),1):_vm._e()],1):_c('el-card',[_c('el-row',{staticStyle:{"margin-bottom":"1rem"},attrs:{"type":"flex","align":"middle","justify":"center"}},[_vm._v(" "+_vm._s(_vm.company.name)+" does not have any campaigns. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }